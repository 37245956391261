import { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import { LoremIpsum } from "lorem-ipsum";
import { Button, Grid, TextField, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import './App.css';

function App() {
	const { t } = useTranslation();

	const [sentencesPerParagraphMax, setSentencesPerParagraphMax] = useState(8);
	const [sentencesPerParagraphMin, setSentencesPerParagraphMin] = useState(4);
	const [wordsPerSentenceMax, setWordsPerSentenceMax] = useState(16);
	const [wordsPerSentenceMin, setWordsPerSentenceMin] = useState(4);
	const [paragraphs, setParagraphs] = useState(3);
	const [paragraphsError, setParagraphsError] = useState('');
	const [sentences, setSentences] = useState(0);
	const [sentencesError, setSentencesError] = useState('');
	const [words, setWords] = useState(0);
	const [wordsError, setWordsError] = useState('');
	const [textParagraphs, setTextParagraphs] = useState("");
	const [textSentences, setTextSentences] = useState("");
	const [textWords, setTextWords] = useState("");

	const handleChange = (e) => {
		setParagraphsError('');
		setSentencesError('');
		setWordsError('');

		if (isNaN(e.target.value)) {
			switch (e.target.id) {
				case 'paragraphs':
					setParagraphsError(t('Please enter a number'));
					break;
				case 'sentences':
					setSentencesError(t('Please enter a number'));
					break;
				case 'words':
					setWordsError(t('Please enter a number'));
					break;
				default:
					break;
			}
		} else {
			const value = e.target.value.length > 0 ? parseInt(e.target.value) : '';

			switch (e.target.id) {
				case 'sentencesPerParagraphMax':
					setSentencesPerParagraphMax(value);
					break;
				case 'sentencesPerParagraphMin':
					setSentencesPerParagraphMin(value);
					break;
				case 'wordsPerSentenceMax':
					setWordsPerSentenceMax(value);
					break;
				case 'wordsPerSentenceMin':
					setWordsPerSentenceMin(value);
					break;
				case 'paragraphs':
					setParagraphs(value);
					break;
				case 'sentences':
					setSentences(value);
					break;
				case 'words':
					setWords(value);
					break;
				default:
					break;
			}
		}
	};

	const handleGenerate = () => {
		const lorem = new LoremIpsum({
			sentencesPerParagraph: {
				max: sentencesPerParagraphMax,
				min: sentencesPerParagraphMin
			},
			wordsPerSentence: {
				max: wordsPerSentenceMax,
				min: wordsPerSentenceMin
			},
		});

		if (paragraphs > 0) {
			let html = lorem.generateParagraphs(paragraphs).split('\n')
				.map((str, i) => {
					return i === 0
						? `<p>Lorem ipsum ${str.charAt(0).toLowerCase()}${str.slice(1)}</p>`
						: `<p>${str}</p>`
				})
				.join('\n');

			setTextParagraphs(html);
		}

		if (sentences > 0) {
			setTextSentences(lorem.generateSentences(sentences));
		}

		if (words > 0) {
			setTextWords(lorem.generateWords(words));
		}
	}

	return (
		<div id="container">
			<Typography variant="h1" component="h1" gutterBottom align="center">Lorem ipsum</Typography>

			<Grid container spacing={3}>
				<Grid item xs={12} display="flex" justifyContent="center" flexDirection="row">
					<TextField id="paragraphs" label={t('Number of paragraphs')} value={paragraphs} onChange={handleChange} variant="outlined" error={paragraphsError.length ? true : false} helperText={paragraphsError} />
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center" flexDirection="row">
					<TextField id="sentences" label={t('Number of sentences')} value={sentences} onChange={handleChange} variant="outlined" error={sentencesError.length ? true : false} helperText={sentencesError} />
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center" flexDirection="row">
					<TextField id="words" label={t('Number of words')} value={words} onChange={handleChange} variant="outlined" error={wordsError.length ? true : false} helperText={wordsError} />
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center" flexDirection="row">
					<Button variant="contained" onClick={handleGenerate}>{t('Generate')}</Button>
				</Grid>
			</Grid>

			{textParagraphs.length ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: textParagraphs }} /> : null}

			{textSentences.length ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: textSentences }} /> : null}

			{textWords.length ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: textWords }} /> : null}

			<Grid container>
				<Grid item xs={12} display="flex" justifyContent="center" flexDirection="row">
					{textParagraphs.length || textSentences.length || textWords.length ? (
						<>
							<CopyToClipboard
								text={textParagraphs}
								onCopy={() => {
									console.log('copied');
									toast.success(t('Copied to clipboard'))
								}}
							>
								<Button variant="contained">{t('Copy to clipboard')}</Button>
							</CopyToClipboard>

							<Toaster />
						</>
					) : null}
				</Grid>
			</Grid>
		</div>
	);
}

export default App;
