import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
	en: {
		translation: {
			"Generate": "Generate",
			"Copy to clipboard": "Copy to clipboard",
			"Copied to clipboard": "Copied to clipboard",
			"Number of paragraphs": "Number of paragraphs",
			"Number of sentences": "Number of sentences",
			"Number of words": "Number of words",
			"Please enter a number": "Please enter a number",
		}
	},
	da: {
		translation: {
			"Generate": "Generer",
			"Copy to clipboard": "Kopier til udklipsholder",
			"Copied to clipboard": "Kopieret til udklipsholder",
			"Number of paragraphs": "Antal afsnit",
			"Number of sentences": "Antal sætninger",
			"Number of words": "Antal ord",
			"Please enter a number": "Indtast venligst et tal",
		}
	}
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: 'da',
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;