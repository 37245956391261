import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();

const theme = createTheme({
	typography: {
		h1: {
			fontSize: '80px',
			fontWeight: '400',
			[defaultTheme.breakpoints.down('md')]: {
				fontSize: '50px',
				marginBottom: '50px',
			},
		},
		body1: {
			fontSize: '18px',
			fontWeight: '300',
		}
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					fontSize: '20px',
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					width: '300px',
					fontSze: '20px',
				}
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					width: '300px',
				},
			},
		},
	},
});

export default theme;
